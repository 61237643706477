import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

import AuthenticationService from './AuthenticationService';

export interface AuthenticationState {
  hasError: boolean;
  isAuthenticated: boolean;
  user: User;
}

export interface User {
  phoneNumber: string;
  pinCode: string;
  displayName: string;
  portalId: number;
  portalTitle: string;
}

export interface AuthenticationRequest {
  phoneNumber: string;
  pinCode: string;
}

export const authenticateAsync = createAsyncThunk(
  'authentication/authenticate',
  async (req: AuthenticationRequest, { dispatch }) => {
    const service = new AuthenticationService();

    const resp = await service.login(req.phoneNumber, req.pinCode);
    if (resp !== undefined && resp !== null) {
      const user: User = {
        phoneNumber: resp!.phoneNumber,
        pinCode: req.pinCode,
        displayName: resp!.displayName,
        portalId: resp!.portalId,
        portalTitle: resp!.portalTitle
      };

      dispatch(persistUser(user));
    } else {
      dispatch(authenticationError(true));
    }
  }
);

let blankState: AuthenticationState = {
  hasError: false,
  isAuthenticated: false,
  user: {
    phoneNumber: '',
    pinCode: '',
    displayName: '',
    portalId: 0,
    portalTitle: ''
  }
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: blankState,
  reducers: {
    loadUser: (state) => {
      const storageState = localStorage.getItem('authentication_state');
      if (storageState !== undefined) {
        const auth = JSON.parse(storageState!);
        return auth;
      }
    },
    persistUser: (state, action: PayloadAction<User>) => {
      state = {
        ...state,
        isAuthenticated: true,
        user: action.payload
      };

      localStorage.setItem('authentication_state', JSON.stringify(state));
      return state;
    },
    logout: (state) => {
      localStorage.removeItem('authentication_state');
      return blankState;
    },
    authenticationError: (state, action: PayloadAction<boolean>) => {
      state = {
        ...state,
        hasError: action.payload
      };

      return state;
    }
  }
});

export const { loadUser, persistUser, logout, authenticationError } = authenticationSlice.actions;
export const selectUser = (state: RootState) => {
  if (state.authentication === null) {
    return blankState.user;
  }

  return state.authentication.user;
};

export const selectAuthenticated = (state: RootState) => {
  if (state.authentication === null) {
    return blankState.isAuthenticated;
  }

  return state.authentication.isAuthenticated;
};

export const selectAuthError = (state: RootState) => {
  if (state.authentication === null) {
    return blankState.hasError;
  }

  return state.authentication.hasError;
};

export default authenticationSlice.reducer;
