import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from '../app/store';
import { Location } from "../locations/LocationService";
import WeatherService, { Weather } from "./WeatherService";

export interface WeatherState {
  weather: Weather | null;
}

export const weatherAsync = createAsyncThunk(
  'weather/fetch',
  async (location: Location, { dispatch }) => {
    const service = new WeatherService();

    const resp = await service.getCurrentConditions(location);
    if (resp !== null) {
      dispatch(updateWeather(resp));
    }
  }
);

let weatherState: WeatherState = {
  weather: null
};

export const weatherSlice = createSlice({
  name: 'weather',
  initialState: weatherState,
  reducers: {
    updateWeather: (state, action: PayloadAction<Weather>) => {
      state = {
        ...state,
        weather: action.payload
      };

      return state;
    }
  }
});

export const { updateWeather } = weatherSlice.actions;
export const selectWeather = (state: RootState) => {
  if (state.weather === null) {
    return null;
  }

  return state.weather.weather;
}

export default weatherSlice.reducer;
