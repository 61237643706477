import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { User } from '../users/authSlice';
import IncidentService, { Incident, Responder, SummaryResponse } from './IncidentService';

export interface IncidentState {
  incidents: Incident[];
  responders: Responder[];
}

export const summaryAsync = createAsyncThunk(
  'incidents/summary',
  async (user: User, { dispatch }) => {
    const service = new IncidentService();
    const auth = `${user.phoneNumber}:${user.pinCode}`
  
    const resp = await service.getSummary(user.portalId, auth);
    if (resp !== undefined && resp !== null) {
      dispatch(updateIncidents(resp));
    }
  }
);

let incidentState: IncidentState = {
  incidents: [],
  responders: []
};

export const incidentSlice = createSlice({
  name: 'incidents',
  initialState: incidentState,
  reducers: {
    updateIncidents: (state, action: PayloadAction<SummaryResponse>) => {
      state = {
        ...state,
        incidents: action.payload.incidents,
        responders: action.payload.responders
      };

      return state;
    }
  }
});

export const { updateIncidents } = incidentSlice.actions;
export const selectActiveIncidents = (state: RootState) => {
  if (state.incidents === null || state.incidents === undefined) {
    return [];
  }

  return state.incidents.incidents;
};

export const selectResponders = (state: RootState) => {
  if (state.incidents.responders === null || state.incidents.responders === undefined) {
    return [];
  }

  return state.incidents.responders;
};

export default incidentSlice.reducer;
