import axios, { AxiosError } from "axios";

export interface AuthenticationResponse {
  phoneNumber: string;
  displayName: string;
  portalId: number;
  portalTitle: string;
}

class AuthenticationService {
  async login(phoneNumber: string, pinCode: string): Promise<AuthenticationResponse | null> {
    try {
      const resp = await axios.post(`https://spotteddog-proxy.novak.org/authenticate`, {
        phoneNumber: phoneNumber,
        pinCode: pinCode
      });

      return resp.data as AuthenticationResponse;
    } catch (error) {
      console.log((error as AxiosError).message);
    }

    return null;
  }
}

export default AuthenticationService;
