import axios, { AxiosError } from 'axios';
import { Location } from '../locations/LocationService';

export interface Condition {
  temperature: number;
  feelsLike: number;
  humidity: number;
  dewPoint: number;
  windSpeed: number;
  windGust: number;
  visibility: number;
  weatherId: number;
  weather: string;
}

export interface HourCondition {
  time: number;
  temperature: number;
  feelsLike: number;
  weather: string;
  weatherId: number;
  precipProbability: number;
}

export interface Forecast {
  time: number;
  maxTemp: number;
  minTemp: number;
  weather: string;
  weatherId: number;
  rain: number;
  snow: number;
  precipProbability: number;
}

export interface WeatherAlert {
  name: string;
  start: number;
  end: number;
}

export interface Weather {
  currentConditions: Condition;
  hourly: HourCondition[];
  daily: Forecast[];
  alerts: WeatherAlert[];
}

class WeatherService {
  /**
   * Returns the current conditions for a provided location
   */
  async getCurrentConditions(location: Location): Promise<Weather | null> {
    console.log('updating weather forecast');

    try {
      const res = await axios.get(`https://spotteddog-proxy.novak.org/weather`, {
        params: {
          latitude: location.latitude,
          longitude: location.longitude
        }
      });

      return res.data as Weather;
    } catch (error) {
      console.log((error as AxiosError).message);
    }

    return null;
  }
}

export default WeatherService;
