import { FC } from 'react';
import moment from 'moment';

import { useAppSelector } from '../app/hooks';
import { selectActiveIncidents } from './incidentSlice';

const IncidentList: FC = () => {
  const incidents = useAppSelector(selectActiveIncidents);

  return (
    <div>
      <div className='incident-header p-2'>
        <span className='title'>Recent Incidents</span>
      </div>
      {incidents.map((incident) =>
        <div key={incident.id} className='incident container p-2'>
          <div className='row'>
            <div className='col'>
              {incident.runNumber != null ?
              <span className='list-run-header'>#{incident.runNumber.split('-')[1].replace(/\b0+/g, '')} {incident.priority}</span>
              :
              <span className='list-run-header'>{incident.priority}</span>
              }
            </div>
            <div className='col-3 pull-right'>
              <span className='list-run-header'>{moment(incident.dispatchTime).format('MM/DD/yyyy HH:mm:ss')}</span>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <span>{incident.nature}</span>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <span className='list-run-address'>{incident.address}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IncidentList;
