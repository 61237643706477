import React, { FC, RefObject, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { authenticateAsync, AuthenticationRequest, selectAuthError, selectAuthenticated, authenticationError } from './authSlice';

import logo from '../images/logo_light.svg';
import './authenticate.scss';

const showError = (ref: RefObject<HTMLDivElement>, message: string) => {
  const node = ref.current;
  if (node !== null) {
    node.innerHTML = message;
    node.style.setProperty('display', 'block');
  }
};

const clearError = (ref: RefObject<HTMLDivElement>) => {
  const node = ref.current;
  if (node !== null) {
    node.style.setProperty('display', 'none');
  }
};

const AuthenticatePage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const didAuthenticate = useAppSelector(selectAuthenticated);
  const hasError = useAppSelector(selectAuthError);

  const errorAlertRef = useRef<HTMLDivElement>(null);

  const [phoneNumber, setPhoneNumber] = useState<string>();
  const handlePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(event.target.value);

  const [pinCode, setPinCode] = useState<string>();
  const handlePinCode = (event: React.ChangeEvent<HTMLInputElement>) => setPinCode(event.target.value);

  const formSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    clearError(errorAlertRef);
    dispatch(authenticationError(false));

    if (phoneNumber === undefined || phoneNumber === '') {
      showError(errorAlertRef, 'Phone number is required.');
      return;
    }

    if (pinCode === undefined || pinCode === '') {
      showError(errorAlertRef, 'Pin code is required.');
      return;
    }

    const req: AuthenticationRequest = {
      phoneNumber: phoneNumber!,
      pinCode: pinCode!
    };

    await dispatch(authenticateAsync(req));
  };

  useEffect(() => {
    if (didAuthenticate) {
      navigate('/', { replace: true });
    }
  });

  if (hasError) {
    showError(errorAlertRef, 'Incorrect phone number or pin code. Please try again.');
  }

  return (
    <>
      <div className='container'>
        <div className='row mt-4 justify-content-center align-items-center'>
          <div className='col-lg-4'>
            <div className='mt-4 text-center'>
              <img src={logo} className='logo' alt='Spotted Dashboard' />
            </div>
            <div className='card mt-4'>
              <div className='card-body'>
                <div ref={errorAlertRef} className='alert alert-danger collapse'></div>
                <form onSubmit={formSubmit}>
                  <input type='text' className='form-control form-input mt-2' placeholder='Phone number' onChange={(e) => { handlePhoneNumber(e); }} />
                  <input type='password' className='form-control form-input mt-3' placeholder='Pin code' onChange={(e) => { handlePinCode(e); }} />
                  <div className='mt-4 text-center'>
                    <button type='submit' className='btn btn-primary col-12'>Login</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthenticatePage;
