import { FC } from 'react';

import './responders.scss';

interface DestinationHeaderProps {
  destination: string;
}

const DestinationHeader: FC<DestinationHeaderProps> = ({ destination }) => {
  return (
    <div className='destination-header p-2'>
      <span className='destination-title'>{destination}</span>
    </div>
  );
};

export default DestinationHeader;
