import { FC } from 'react';
import { useAppSelector } from '../app/hooks';
import { selectResponders } from '../incident/incidentSlice';
import { selectCurrentLocation } from '../locations/locationSlice';
import DestinationHeader from './DestinationHeader';
import ResponderGroup from './ResponderGroup';

const RespondersList: FC = () => {
  const responders = useAppSelector(selectResponders);
  var destinations = Array.from(new Set(responders.flatMap((r) => r.respondingTo)));

  const currentLocation = useAppSelector(selectCurrentLocation);
  if (currentLocation !== undefined && currentLocation !== null) {
    destinations = [
      ...destinations.filter((destination) => currentLocation !== undefined && currentLocation !== null && currentLocation.name === destination),
      ...destinations.filter((destination) => (currentLocation === undefined || currentLocation === null) || currentLocation.name !== destination)
    ]
  }

  return (
    <div>
      {destinations.map((destination) =>
        <div key={destination}>
          <DestinationHeader destination={destination} />
          <ResponderGroup destination={destination} allResponders={responders} />
        </div>
      )}
    </div>
  );
};

export default RespondersList;
