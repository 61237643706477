import { configureStore } from '@reduxjs/toolkit';
import authenticationReducer from '../users/authSlice';
import incidentsReducer from '../incident/incidentSlice';
import locationsReducer from '../locations/locationSlice';
import weatherReducer from '../weather/weatherSlice';

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    incidents: incidentsReducer,
    locations: locationsReducer,
    weather: weatherReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
