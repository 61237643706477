import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import IncidentDetail from '../incident/IncidentDetail';
import IncidentList from '../incident/IncidentList';
import IncidentMap from '../incident/IncidentMap';
import { Incident } from '../incident/IncidentService';
import { selectActiveIncidents, selectResponders, summaryAsync } from '../incident/incidentSlice';
import Navbar from '../nav/Navbar';
import RespondersList from '../responders/RespondersList';
import { selectUser } from '../users/authSlice';
import WeatherConditions from '../weather/WeatherConditions';

import './dashboard.scss';

const DashboardPage: FC = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const incidents = useAppSelector(selectActiveIncidents);

  const activeIncidents = incidents.filter((i) => i.endTime < 0);
  const responders = useAppSelector(selectResponders);
  
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [activeIncident, setActiveIncident] = useState<Incident>();

  useEffect(() => {
    if (incidents === null || incidents.length === 0) {
      dispatch(summaryAsync(user));
    }
  });

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(summaryAsync(user));

      if (activeIncidents.length > 0) {
        setActiveIncident(activeIncidents[activeIndex]);

        if ((activeIndex + 1) < activeIncidents.length) {
          setActiveIndex(activeIndex+1);
        } else {
          setActiveIndex(0);
        }
      } else {
        setActiveIncident(undefined);
      }
    }, 5000);
    return () => clearInterval(timer);
  });

  return (
    <div>
      <Navbar />
      <div className='container-fluid'>
        <div className='row'>
          {(responders.length > 0) &&
          <div className='responders col m-0 p-0'>
            <RespondersList />
          </div>
          }
          {(responders.length === 0) &&
          <div className='col m-0 p-0'>
            <IncidentList />
          </div>
          }
          {(activeIncident !== undefined) &&
          <div className='incident col m-0 p-0'>
            <IncidentMap incident={activeIncident!} />
            <IncidentDetail incident={activeIncident!} />
          </div>
          }
          {(activeIncident === undefined) &&
          <div className='col m-0 p-0'>
            <WeatherConditions />
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
