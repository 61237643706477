import { FC, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faUmbrella, faSnowflake } from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectCurrentLocation } from '../locations/locationSlice';
import { selectWeather, weatherAsync } from './weatherSlice';

import './weather.scss';
import moment from 'moment';
import { WeatherIcons } from './WeatherIcons';

const WeatherConditions: FC = () => {
  const dispatch = useAppDispatch();
  const location = useAppSelector(selectCurrentLocation);

  const weather = useAppSelector(selectWeather);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);

  useEffect(() => {
    const lastUpdate = localStorage.getItem('last_weather_update');

    if (!initialLoad && location !== null && location !== undefined && lastUpdate !== null && (weather === null || weather === undefined)) {
      setInitialLoad(true);

      dispatch(weatherAsync(location));
      localStorage.setItem('last_weather_update', Date.now().toString());
    }
  }, [initialLoad, location, weather, dispatch]);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = Date.now();
      const lastUpdate = localStorage.getItem('last_weather_update');

      if (location !== undefined && location !== null) {
        if (lastUpdate === null) {
          dispatch(weatherAsync(location));
          localStorage.setItem('last_weather_update', now.toString());
          return;
        }

        const nowHours = moment(now).hour();
        const lastUpdateHours = moment(parseInt(lastUpdate)).hour();

        const nowDay = moment(now).day();
        const lastUpdateDay = moment(parseInt(lastUpdate)).day();

        if (now - parseInt(lastUpdate) > 900000 || nowHours !== lastUpdateHours || nowDay !== lastUpdateDay) {
          dispatch(weatherAsync(location));
          localStorage.setItem('last_weather_update', now.toString());
        }
      }
    }, 5000);

    return () => clearInterval(timer);
  });

  return (
    <div className='p-2'>
      {weather?.alerts.map((alert) =>
      <div className='alert alert-danger d-flex align-items-center' role='alert'>
        <div><FontAwesomeIcon className='alert-icon' icon={faTriangleExclamation} /> <span className='alert-name'>{alert.name}</span> <span className='alert-dates'>{moment(alert.start * 1000).format('MMM DD HH:mm')} to {moment(alert.end * 1000).format('MMM DD HH:mm')}</span></div>
      </div>
      )}
      <div className='container mt-4'>
        <div className='d-flex flex-column align-items-center'>
          <div><FontAwesomeIcon icon={WeatherIcons.getFontAwesomeIcon(weather?.currentConditions.weatherId ?? 0)} size='10x' /></div>
          <p className='mt-2'>
            <span className='conditions'>{weather?.currentConditions.weather}</span>
          </p>
        </div>
      </div>
      <div className='card mt-4'>
        <div className='card-header title'>Currently</div>
        <div className='card-body'>
          <div className='d-flex flex-row justify-content-center'>
            <div className='col-2 d-flex flex-column align-items-center'>
              <span className='measurement-title'>Temperature</span>
              <span className='measurement'>{Math.round(weather?.currentConditions.temperature ?? 0).toFixed(0)}&#176;</span>
            </div>
            <div className='col-2 d-flex flex-column align-items-center'>
              <span className='measurement-title'>Feels like</span>
              <span className='measurement'>{Math.round(weather?.currentConditions.feelsLike ?? 0).toFixed(0)}&#176;</span>
            </div>
            <div className='col-2 d-flex flex-column align-items-center'>
              <span className='measurement-title'>Wind</span>
              <span className='measurement'>{Math.round(weather?.currentConditions.windSpeed ?? 0).toFixed(0)} mph</span>
            </div>
            <div className='col-2 d-flex flex-column align-items-center'>
              <span className='measurement-title'>Wind Gusts</span>
              <span className='measurement'>{Math.round(weather?.currentConditions.windGust ?? 0).toFixed(0)} mph</span>
            </div>
            <div className='col-2 d-flex flex-column align-items-center'>
              <span className='measurement-title'>Humidity</span>
              <span className='measurement'>{Math.round(weather?.currentConditions.humidity ?? 0).toFixed(0)}%</span>
            </div>
          </div>
        </div>
      </div>
      <div className='card mt-4'>
        <div className='card-header title'>Hourly Forecast</div>
        <div className='card-body'>
          <div className='d-flex flex-row'>
            {weather?.hourly.map((forecast) =>
            <div key={forecast.time} className='forecast col-2 d-flex flex-column align-items-center'>
              {moment(Date.now()).hour() === moment(forecast.time * 1000).hour() &&
              <span>Now</span>
              }
              {moment(Date.now()).hour() !== moment(forecast.time * 1000).hour() &&
              <span>{moment(forecast.time * 1000).format('HH:mm')}</span>
              }
              <span className='m-2'><FontAwesomeIcon icon={WeatherIcons.getFontAwesomeIcon(forecast.weatherId)} size='2x' /></span>
              <span>{Math.round(forecast.temperature).toFixed(0)}&#176;</span>
              <span className='feels-like'>Feels like {Math.round(forecast.feelsLike).toFixed(0)}&#176;</span>
              {forecast.precipProbability > 0 &&
              <span><FontAwesomeIcon icon={faUmbrella} /> {forecast.precipProbability * 100}%</span>
              }
            </div>
            )}
          </div>
        </div>
      </div>
      <div className='card mt-4'>
        <div className='card-header title'>Daily Forecast</div>
        <div className='card-body'>
          <div className='d-flex flex-row'>
            {weather?.daily.map((forecast) =>
            <div key={forecast.time} className='forecast col-2 d-flex flex-column align-items-center'>
              { moment(Date.now()).weekday() === moment(forecast.time * 1000).weekday() &&
              <div>
              <span>Today</span>
              </div>
              }
              {moment(Date.now()).weekday() !== moment(forecast.time * 1000).weekday() &&
              <span>{moment(forecast.time * 1000).format('dddd')}</span>
              }

              <span className='m-2 light-color'><FontAwesomeIcon icon={WeatherIcons.getFontAwesomeIcon(forecast.weatherId)} size='2x' /></span>
              <span>{forecast.weather}</span>
              <span><span className='lo-temp'>{Math.round(forecast.minTemp).toFixed(0)}&#176;</span> <span className='hi-temp'>{Math.round(forecast.maxTemp).toFixed(0)}&#176;</span></span>
              {(forecast.precipProbability > 0 && forecast.snow > 0) &&
              <span><FontAwesomeIcon icon={faSnowflake} /> {forecast.precipProbability * 100}%</span>
              }
              {(forecast.precipProbability > 0 && forecast.rain > 0) &&
              <span><FontAwesomeIcon icon={faUmbrella} /> {forecast.precipProbability * 100}%</span>
              }
            </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeatherConditions;
