import { FC } from 'react';
import moment from 'moment';

import { Incident } from './IncidentService';
import './incident.scss';

interface IncidentDetailProps {
  incident: Incident;
}

const IncidentDetail: FC<IncidentDetailProps> = ({incident}) => {
  const runNumber = incident.runNumber.split('-')[1].replace(/\b0+/g, '');

  return (
    <div className='container'>
      <div className='row mt-3'>
        <div className='col'>
          <div className='card'>
            <div className='card-header title'>Incident {runNumber}</div>
            <div className='card-body'>
              <span className='subtitle'>Dispatch Time</span>
              <p className='detail'>{moment(incident.dispatchTime).format('MMM DD, yyyy HH:mm:ss')}</p>
              <span className='subtitle'>{incident.priority}</span>
              <p className='detail'>{incident.nature}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-2'>
        <div className='col'>
          <div className='card'>
            <div className='card-header title'>Location</div>
            <div className='card-body'>
              <span className='subtitle'>Station</span>
              <p className='detail'>{incident.station}</p>
              <span className='subtitle'>Address</span>
              <p className='detail'>{incident.address}</p>
              <span className='subtitle'>Cross Streets</span>
              <p className='detail'>{incident.crossStreet}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncidentDetail;
