import axios, { AxiosError } from 'axios';

export interface Location {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  address: string;
}

class LocationService {
  async getLocations(portalId: number, auth: string): Promise<Location[] | null> {
    try {
      const res = await axios.get(`https://spotteddog-proxy.novak.org/portals/${portalId}/locations`, {
        headers: {
          'Authorization': auth
        }
      });
      return res.data as Location[];
    } catch (error) {
      console.log((error as AxiosError).message);
    }

    return null;
  }
}

export default LocationService;
