/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { logout, selectUser } from '../users/authSlice';
import moment from 'moment';

import { locationAsync, selectCurrentLocation, selectLocations, setSelectedLocation } from '../locations/locationSlice';
import { Location } from '../locations/LocationService';

import logo from '../images/logo_light.svg';
import './navbar.scss';

const Navbar: FC = () => {
  const user = useAppSelector(selectUser);
  const timeRef = useRef<HTMLSpanElement>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const locations = useAppSelector(selectLocations);
  const currentLocation = useAppSelector(selectCurrentLocation);

  const onClickLogout = () => {
    dispatch(logout());
    navigate('/login', { replace: true });
  };

  const onClickLocation = (location: Location) => {
    dispatch(setSelectedLocation(location));
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeRef.current) {
        timeRef.current.innerText = moment(Date.now()).format('HH:mm:ss');
      }
    }, 1000);
    return () => clearInterval(timer);
  });

  useEffect(() => {
    if (locations.length <= 0) {
      dispatch(locationAsync(user));
    }
  });

  return (
    <nav className='navbar navbar-dark'>
      <span className='nav-title mx-3'>{user.portalTitle}</span>
      <div className='my-auto ml-auto nav-right'>
        <span ref={timeRef} className='clock me-3 nav-item'>{moment(Date.now()).format('HH:mm:ss')}</span>
        <li className='nav-item dropdown nav-right me-3 dropstart'>
          <a className='nav-link' data-bs-toggle='dropdown' aria-expanded='false'><img className='logo-small' src={logo} alt='Spotted Dashboard'/></a>
          <ul className='dropdown-menu dropdown-menu-dark'>
            {locations.length > 0 &&
            <li key='header' className='dropdown-header'>Locations</li>
            }
            {locations.map((location) => (
              <li key={location.id}><a className={(currentLocation !== null && currentLocation !== undefined && currentLocation.id === location.id) ? 'dropdown-item active': 'dropdown-item'} onClick={() => onClickLocation(location)}>{location.name}</a></li>
            ))}
            {locations.length > 0 &&
            <li key='divider' className='dropdown-divider'></li>
            }
            <li key='logout'><a className='dropdown-item' onClick={() => onClickLogout()}>Logout</a></li>
          </ul>
        </li>
      </div>
    </nav>
  );
};

export default Navbar;
