import { FC } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import AuthenticatePage from '../users/AuthenticatePage';
import DashboardPage from '../dashboard/DashboardPage';

import { useAppDispatch, useAppSelector } from './hooks';
import { loadUser, selectAuthenticated } from '../users/authSlice';

import './app.scss';
import { loadLocations } from '../locations/locationSlice';

const PrivateRoute: FC = () => {
  const location = useLocation();
  const isAuthenticated = useAppSelector(selectAuthenticated);

  return isAuthenticated ? <Outlet /> : <Navigate to='/login' state={{ from: location }} />;
};

const App: FC = () => {
  const dispatch = useAppDispatch();
  dispatch(loadUser());
  dispatch(loadLocations());

  return (
    <Routes>
      <Route path='/' element={<PrivateRoute />}>
        <Route path='/' element={<DashboardPage />} />
      </Route>
      <Route path='/login' element={<AuthenticatePage />} />
    </Routes>
  );
};

export default App;
