import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { User } from "../users/authSlice";
import LocationService, { Location } from "./LocationService";

export interface LocationState {
  locations: Location[];
  selectedLocation: Location | null;
}

export const locationAsync = createAsyncThunk(
  'locations/get',
  async (user: User, { dispatch }) => {
    const service = new LocationService();
    const auth = `${user.phoneNumber}:${user.pinCode}`;

    const resp = await service.getLocations(user.portalId, auth);
    if (resp !== undefined && resp !== null) {
      dispatch(updateLocations(resp));
    }
  }
);

let locationState: LocationState = {
  locations: [],
  selectedLocation: null
};

export const locationSlice = createSlice({
  name: 'locations',
  initialState: locationState,
  reducers: {
    updateLocations: (state, action: PayloadAction<Location[]>) => {
      state = {
        ...state,
        locations: action.payload
      };

      localStorage.setItem('location_state', JSON.stringify(state));
      return state;
    },
    setSelectedLocation: (state, action: PayloadAction<Location>) => {
      state = {
        ...state,
        selectedLocation: action.payload
      };

      localStorage.setItem('location_state', JSON.stringify(state));
      return state;
    },
    loadLocations: (state) => {
      const locationState = localStorage.getItem('location_state');
      if (locationState !== undefined) {
        const locations = JSON.parse(locationState!);
        return locations;
      }
    }
  }
});

export const { updateLocations, setSelectedLocation, loadLocations } = locationSlice.actions;
export const selectLocations = (state: RootState) => {
  if (state.locations === null) {
    return [];
  }

  return state.locations.locations;
};

export const selectCurrentLocation = (state: RootState) => {
  if (state.locations === null) {
    return null;
  }

  return state.locations.selectedLocation;
}

export default locationSlice.reducer;
