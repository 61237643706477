import { FC } from 'react';
import { Incident } from './IncidentService';

interface IncidentMapProps {
  incident: Incident;
}

const IncidentMap: FC<IncidentMapProps> = ({incident}) => {
  const url = `https://maps.googleapis.com/maps/api/staticmap?center=`+incident.latitude+`,`+incident.longitude+`&zoom=15&size=600x300&scale=2&maptype=roadmap&markers=color:red%7Clabel:Incident%7C`+incident.latitude+`,`+incident.longitude+`&key=AIzaSyDgdqgyVGFelaYcdOlm0nJgs3JWjq1g7Fs`;

  return (
    <>
      <img className='w-100' src={url} alt='incident map' />
    </>
  );
};

export default IncidentMap;
