import { FC } from 'react';
import { Responder } from '../incident/IncidentService';

interface ResponderGroupProps {
  destination: string;
  allResponders: Responder[];
}

const ResponderGroup: FC<ResponderGroupProps> = ({destination, allResponders}) => {
  const responders = allResponders.filter((r) => r.respondingTo === destination && !r.name.startsWith('39'));

  return (
    <>
      {responders.map((r) =>
        <div key={r.userId} className='responder container p-1'>
          <div className='row'>
            <div className='col-10'>
              <span className='responder-name ms-2'>{r.name}</span>
              <span className='responder-qualifications ms-2'>{r.qualifications}</span>
            </div>
            <div className='col-2 pull-right'>
              <span className='responder-eta me-2'>{r.estimatedArrival.startsWith('-') ? 'ARR' : r.estimatedArrival}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResponderGroup;
