import axios, { AxiosError } from 'axios';

export interface Incident {
  id: number;
  status: string;
  address: string;
  summary: string;
  runNumber: string;
  latitude: string;
  longitude: string;
  district: string;
  station: string;
  priority: string;
  nature: string;
  crossStreet: string;
  dispatchTime: string;
  endTime: number;
}

export interface Responder {
  userId: number;
  name: string;
  qualifications: string;
  respondingTo: string;
  estimatedArrival: string;
}

export interface SummaryResponse {
  incidents: Incident[];
  responders: Responder[];
}

class IncidentService {
  async getSummary(portalId: number, auth: string): Promise<SummaryResponse | null> {
    try {
      const res = await axios.get(`https://spotteddog-proxy.novak.org/portals/${portalId}/incidents/summary`, {
        headers: {
          'Authorization': auth
        }
      });
      return res.data as SummaryResponse;
    } catch (error) {
      console.log((error as AxiosError).message);
    }

    return {
      incidents: [],
      responders: []
    };
  }
}

export default IncidentService;
